.container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-right: 8px;
  padding-left: 8px;
}

.container-fluid {
  padding-right: 16px;
  padding-left: 16px;
}

@media only screen and (min-width: 576px) {
  .container {
    width: 560px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    width: 752px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    width: 976px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1184px;
    max-width: 100%;
  }
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-offset-0,
.col-sm-offset-1,
.col-sm-offset-2,
.col-sm-offset-3,
.col-sm-offset-4,
.col-sm-offset-5,
.col-sm-offset-6,
.col-sm-offset-7,
.col-sm-offset-8,
.col-sm-offset-9,
.col-sm-offset-10,
.col-sm-offset-11,
.col-sm-offset-12,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-offset-0,
.col-md-offset-1,
.col-md-offset-2,
.col-md-offset-3,
.col-md-offset-4,
.col-md-offset-5,
.col-md-offset-6,
.col-md-offset-7,
.col-md-offset-8,
.col-md-offset-9,
.col-md-offset-10,
.col-md-offset-11,
.col-md-offset-12,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-offset-0,
.col-lg-offset-1,
.col-lg-offset-2,
.col-lg-offset-3,
.col-lg-offset-4,
.col-lg-offset-5,
.col-lg-offset-6,
.col-lg-offset-7,
.col-lg-offset-8,
.col-lg-offset-9,
.col-lg-offset-10,
.col-lg-offset-11,
.col-lg-offset-12,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-offset-0,
.col-xl-offset-1,
.col-xl-offset-2,
.col-xl-offset-3,
.col-xl-offset-4,
.col-xl-offset-5,
.col-xl-offset-6,
.col-xl-offset-7,
.col-xl-offset-8,
.col-xl-offset-9,
.col-xl-offset-10,
.col-xl-offset-11,
.col-xl-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-basis: 100%;
  padding-right: 8px;
  padding-left: 8px;
  max-width: 100%;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.start-xs {
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  justify-content: center;
  text-align: center;
}

.end-xs {
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

.initial-order-xs {
  order: initial;
}

@media only screen and (min-width: 576px) {
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-sm {
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }

  .initial-order-sm {
    order: initial;
  }
}

@media only screen and (min-width: 768px) {
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-md {
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  .end-md {
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }

  .initial-order-md {
    order: initial;
  }
}

@media only screen and (min-width: 992px) {
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }

  .initial-order-lg {
    order: initial;
  }
}

@media only screen and (min-width: 1200px) {
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-offset-0,
  .col-xl-offset-1,
  .col-xl-offset-2,
  .col-xl-offset-3,
  .col-xl-offset-4,
  .col-xl-offset-5,
  .col-xl-offset-6,
  .col-xl-offset-7,
  .col-xl-offset-8,
  .col-xl-offset-9,
  .col-xl-offset-10,
  .col-xl-offset-11,
  .col-xl-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xl-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-xl-offset-0 {
    margin-left: 0;
  }

  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xl-offset-3 {
    margin-left: 25%;
  }

  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xl-offset-6 {
    margin-left: 50%;
  }

  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xl-offset-9 {
    margin-left: 75%;
  }

  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-xl {
    justify-content: flex-start;
    text-align: start;
  }

  .center-xl {
    justify-content: center;
    text-align: center;
  }

  .end-xl {
    justify-content: flex-end;
    text-align: end;
  }

  .top-xl {
    align-items: flex-start;
  }

  .middle-xl {
    align-items: center;
  }

  .bottom-xl {
    align-items: flex-end;
  }

  .around-xl {
    justify-content: space-around;
  }

  .between-xl {
    justify-content: space-between;
  }

  .first-xl {
    order: -1;
  }

  .last-xl {
    order: 1;
  }

  .initial-order-xl {
    order: initial;
  }
}

@media only screen and (max-width: 575px) {
  .hidden-xs {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .hidden-xl {
    display: none;
  }
}
.flexboxgrid2__container___3skQK {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-right: 8px;
  padding-left: 8px;
}

.flexboxgrid2__container-fluid___XKLhm {
  padding-right: 16px;
  padding-left: 16px;
}

@media only screen and (min-width: 576px) {
  .flexboxgrid2__container___3skQK {
    width: 560px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .flexboxgrid2__container___3skQK {
    width: 752px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .flexboxgrid2__container___3skQK {
    width: 976px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .flexboxgrid2__container___3skQK {
    width: 1184px;
    max-width: 100%;
  }
}

.flexboxgrid2__row___ZtOZv {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.flexboxgrid2__row___ZtOZv.flexboxgrid2__reverse___3oCGT {
  flex-direction: row-reverse;
}

.flexboxgrid2__col___2Z6TW.flexboxgrid2__reverse___3oCGT {
  flex-direction: column-reverse;
}

.flexboxgrid2__col-xs___2PLFU,
.flexboxgrid2__col-xs-1___1ys2K,
.flexboxgrid2__col-xs-2___1oFs0,
.flexboxgrid2__col-xs-3___1Q5cW,
.flexboxgrid2__col-xs-4___3pHqi,
.flexboxgrid2__col-xs-5___3JeAf,
.flexboxgrid2__col-xs-6___1Zplx,
.flexboxgrid2__col-xs-7___2rjoo,
.flexboxgrid2__col-xs-8___3Pbgu,
.flexboxgrid2__col-xs-9___zDP7a,
.flexboxgrid2__col-xs-10___Zo7_E,
.flexboxgrid2__col-xs-11___mXqV2,
.flexboxgrid2__col-xs-12___AdoKE,
.flexboxgrid2__col-xs-offset-0___3NAsN,
.flexboxgrid2__col-xs-offset-1___3K_gC,
.flexboxgrid2__col-xs-offset-2___2Ga73,
.flexboxgrid2__col-xs-offset-3___3c_Ft,
.flexboxgrid2__col-xs-offset-4___3TWUy,
.flexboxgrid2__col-xs-offset-5___1yWoT,
.flexboxgrid2__col-xs-offset-6___al_7H,
.flexboxgrid2__col-xs-offset-7___2J_G7,
.flexboxgrid2__col-xs-offset-8___1RGyW,
.flexboxgrid2__col-xs-offset-9___3OtIT,
.flexboxgrid2__col-xs-offset-10___109SK,
.flexboxgrid2__col-xs-offset-11___1fcLq,
.flexboxgrid2__col-xs-offset-12___3UGEQ,
.flexboxgrid2__col-sm___3UNLq,
.flexboxgrid2__col-sm-1___2u7Tq,
.flexboxgrid2__col-sm-2___39LGZ,
.flexboxgrid2__col-sm-3___1HPMt,
.flexboxgrid2__col-sm-4___3SO93,
.flexboxgrid2__col-sm-5___2Buhm,
.flexboxgrid2__col-sm-6___1l2Kt,
.flexboxgrid2__col-sm-7___d6sUa,
.flexboxgrid2__col-sm-8___g6l7V,
.flexboxgrid2__col-sm-9___etIBb,
.flexboxgrid2__col-sm-10___Bxvht,
.flexboxgrid2__col-sm-11___PiXUP,
.flexboxgrid2__col-sm-12___2sYMg,
.flexboxgrid2__col-sm-offset-0___oljZ3,
.flexboxgrid2__col-sm-offset-1___1ywTD,
.flexboxgrid2__col-sm-offset-2___1X0hd,
.flexboxgrid2__col-sm-offset-3___2QOr2,
.flexboxgrid2__col-sm-offset-4___3inAM,
.flexboxgrid2__col-sm-offset-5___2Ihhn,
.flexboxgrid2__col-sm-offset-6___21xzL,
.flexboxgrid2__col-sm-offset-7___1ypYV,
.flexboxgrid2__col-sm-offset-8___ymjV3,
.flexboxgrid2__col-sm-offset-9___1SxN0,
.flexboxgrid2__col-sm-offset-10___2qwiO,
.flexboxgrid2__col-sm-offset-11___3zRYq,
.flexboxgrid2__col-sm-offset-12___2fnwd,
.flexboxgrid2__col-md___srMeB,
.flexboxgrid2__col-md-1___3RdWY,
.flexboxgrid2__col-md-2___3j8qs,
.flexboxgrid2__col-md-3___2Th4S,
.flexboxgrid2__col-md-4___3pbbS,
.flexboxgrid2__col-md-5___1Svz9,
.flexboxgrid2__col-md-6___1wIAi,
.flexboxgrid2__col-md-7___3z1EO,
.flexboxgrid2__col-md-8___2Dm-W,
.flexboxgrid2__col-md-9___1nXvw,
.flexboxgrid2__col-md-10___3Br2r,
.flexboxgrid2__col-md-11___3gKDL,
.flexboxgrid2__col-md-12___2t4Kh,
.flexboxgrid2__col-md-offset-0___1twEm,
.flexboxgrid2__col-md-offset-1___12ZU0,
.flexboxgrid2__col-md-offset-2___1dGCS,
.flexboxgrid2__col-md-offset-3___XMXnG,
.flexboxgrid2__col-md-offset-4___3TnIN,
.flexboxgrid2__col-md-offset-5___EGfBj,
.flexboxgrid2__col-md-offset-6___3Kb3E,
.flexboxgrid2__col-md-offset-7___21XFw,
.flexboxgrid2__col-md-offset-8___qnljU,
.flexboxgrid2__col-md-offset-9___kdDX2,
.flexboxgrid2__col-md-offset-10___284iF,
.flexboxgrid2__col-md-offset-11___WXAgk,
.flexboxgrid2__col-md-offset-12___2XYlU,
.flexboxgrid2__col-lg___3u7lk,
.flexboxgrid2__col-lg-1___2y0lP,
.flexboxgrid2__col-lg-2___1x6vt,
.flexboxgrid2__col-lg-3___37wpY,
.flexboxgrid2__col-lg-4___RwCNM,
.flexboxgrid2__col-lg-5___37365,
.flexboxgrid2__col-lg-6___NeTjn,
.flexboxgrid2__col-lg-7___3bixv,
.flexboxgrid2__col-lg-8___2YhQ1,
.flexboxgrid2__col-lg-9___2e0uZ,
.flexboxgrid2__col-lg-10___3X-8g,
.flexboxgrid2__col-lg-11___1Ymgu,
.flexboxgrid2__col-lg-12___p4dm-,
.flexboxgrid2__col-lg-offset-0___YMDi3,
.flexboxgrid2__col-lg-offset-1___2mUfM,
.flexboxgrid2__col-lg-offset-2___2PSlK,
.flexboxgrid2__col-lg-offset-3___2ZEsJ,
.flexboxgrid2__col-lg-offset-4___oUBjv,
.flexboxgrid2__col-lg-offset-5___2_pNE,
.flexboxgrid2__col-lg-offset-6___1bZES,
.flexboxgrid2__col-lg-offset-7___26quH,
.flexboxgrid2__col-lg-offset-8___3kkd3,
.flexboxgrid2__col-lg-offset-9___22Nlu,
.flexboxgrid2__col-lg-offset-10___32R4D,
.flexboxgrid2__col-lg-offset-11___3mcm9,
.flexboxgrid2__col-lg-offset-12___1CzWw,
.flexboxgrid2__col-xl___3OIWS,
.flexboxgrid2__col-xl-1___1x-yZ,
.flexboxgrid2__col-xl-2___oe1yn,
.flexboxgrid2__col-xl-3___1DZkD,
.flexboxgrid2__col-xl-4___e7X-g,
.flexboxgrid2__col-xl-5___1K3om,
.flexboxgrid2__col-xl-6___pj3oz,
.flexboxgrid2__col-xl-7___2lbXv,
.flexboxgrid2__col-xl-8___2T9rc,
.flexboxgrid2__col-xl-9___4Cdy9,
.flexboxgrid2__col-xl-10___pgLUE,
.flexboxgrid2__col-xl-11___fOAzP,
.flexboxgrid2__col-xl-12___1lxVN,
.flexboxgrid2__col-xl-offset-0___rTVg4,
.flexboxgrid2__col-xl-offset-1___1KRTF,
.flexboxgrid2__col-xl-offset-2___3XTdA,
.flexboxgrid2__col-xl-offset-3___1u7VM,
.flexboxgrid2__col-xl-offset-4___1U3cj,
.flexboxgrid2__col-xl-offset-5___1m-Bk,
.flexboxgrid2__col-xl-offset-6___zqsMR,
.flexboxgrid2__col-xl-offset-7___8fHBq,
.flexboxgrid2__col-xl-offset-8___1LruZ,
.flexboxgrid2__col-xl-offset-9___3oTGD,
.flexboxgrid2__col-xl-offset-10___2eReq,
.flexboxgrid2__col-xl-offset-11___kuo4A,
.flexboxgrid2__col-xl-offset-12___1WvjR {
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-basis: 100%;
  padding-right: 8px;
  padding-left: 8px;
  max-width: 100%;
}

.flexboxgrid2__col-xs___2PLFU {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.flexboxgrid2__col-xs-1___1ys2K {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.flexboxgrid2__col-xs-2___1oFs0 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.flexboxgrid2__col-xs-3___1Q5cW {
  flex-basis: 25%;
  max-width: 25%;
}

.flexboxgrid2__col-xs-4___3pHqi {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.flexboxgrid2__col-xs-5___3JeAf {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.flexboxgrid2__col-xs-6___1Zplx {
  flex-basis: 50%;
  max-width: 50%;
}

.flexboxgrid2__col-xs-7___2rjoo {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.flexboxgrid2__col-xs-8___3Pbgu {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.flexboxgrid2__col-xs-9___zDP7a {
  flex-basis: 75%;
  max-width: 75%;
}

.flexboxgrid2__col-xs-10___Zo7_E {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.flexboxgrid2__col-xs-11___mXqV2 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.flexboxgrid2__col-xs-12___AdoKE {
  flex-basis: 100%;
  max-width: 100%;
}

.flexboxgrid2__col-xs-offset-0___3NAsN {
  margin-left: 0;
}

.flexboxgrid2__col-xs-offset-1___3K_gC {
  margin-left: 8.33333333%;
}

.flexboxgrid2__col-xs-offset-2___2Ga73 {
  margin-left: 16.66666667%;
}

.flexboxgrid2__col-xs-offset-3___3c_Ft {
  margin-left: 25%;
}

.flexboxgrid2__col-xs-offset-4___3TWUy {
  margin-left: 33.33333333%;
}

.flexboxgrid2__col-xs-offset-5___1yWoT {
  margin-left: 41.66666667%;
}

.flexboxgrid2__col-xs-offset-6___al_7H {
  margin-left: 50%;
}

.flexboxgrid2__col-xs-offset-7___2J_G7 {
  margin-left: 58.33333333%;
}

.flexboxgrid2__col-xs-offset-8___1RGyW {
  margin-left: 66.66666667%;
}

.flexboxgrid2__col-xs-offset-9___3OtIT {
  margin-left: 75%;
}

.flexboxgrid2__col-xs-offset-10___109SK {
  margin-left: 83.33333333%;
}

.flexboxgrid2__col-xs-offset-11___1fcLq {
  margin-left: 91.66666667%;
}

.flexboxgrid2__start-xs___2gtAf {
  justify-content: flex-start;
  text-align: start;
}

.flexboxgrid2__center-xs___2GJwn {
  justify-content: center;
  text-align: center;
}

.flexboxgrid2__end-xs___1Jy9a {
  justify-content: flex-end;
  text-align: end;
}

.flexboxgrid2__top-xs___3ApF9 {
  align-items: flex-start;
}

.flexboxgrid2__middle-xs___2qJAd {
  align-items: center;
}

.flexboxgrid2__bottom-xs___2sMuK {
  align-items: flex-end;
}

.flexboxgrid2__around-xs___1h6nu {
  justify-content: space-around;
}

.flexboxgrid2__between-xs___1Bml9 {
  justify-content: space-between;
}

.flexboxgrid2__first-xs___2ydrY {
  order: -1;
}

.flexboxgrid2__last-xs___1TmDf {
  order: 1;
}

.flexboxgrid2__initial-order-xs___1lJw5 {
  order: initial;
}

@media only screen and (min-width: 576px) {
  .flexboxgrid2__col-sm___3UNLq {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .flexboxgrid2__col-sm-1___2u7Tq {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .flexboxgrid2__col-sm-2___39LGZ {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .flexboxgrid2__col-sm-3___1HPMt {
    flex-basis: 25%;
    max-width: 25%;
  }

  .flexboxgrid2__col-sm-4___3SO93 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .flexboxgrid2__col-sm-5___2Buhm {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .flexboxgrid2__col-sm-6___1l2Kt {
    flex-basis: 50%;
    max-width: 50%;
  }

  .flexboxgrid2__col-sm-7___d6sUa {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .flexboxgrid2__col-sm-8___g6l7V {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .flexboxgrid2__col-sm-9___etIBb {
    flex-basis: 75%;
    max-width: 75%;
  }

  .flexboxgrid2__col-sm-10___Bxvht {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .flexboxgrid2__col-sm-11___PiXUP {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .flexboxgrid2__col-sm-12___2sYMg {
    flex-basis: 100%;
    max-width: 100%;
  }

  .flexboxgrid2__col-sm-offset-0___oljZ3 {
    margin-left: 0;
  }

  .flexboxgrid2__col-sm-offset-1___1ywTD {
    margin-left: 8.33333333%;
  }

  .flexboxgrid2__col-sm-offset-2___1X0hd {
    margin-left: 16.66666667%;
  }

  .flexboxgrid2__col-sm-offset-3___2QOr2 {
    margin-left: 25%;
  }

  .flexboxgrid2__col-sm-offset-4___3inAM {
    margin-left: 33.33333333%;
  }

  .flexboxgrid2__col-sm-offset-5___2Ihhn {
    margin-left: 41.66666667%;
  }

  .flexboxgrid2__col-sm-offset-6___21xzL {
    margin-left: 50%;
  }

  .flexboxgrid2__col-sm-offset-7___1ypYV {
    margin-left: 58.33333333%;
  }

  .flexboxgrid2__col-sm-offset-8___ymjV3 {
    margin-left: 66.66666667%;
  }

  .flexboxgrid2__col-sm-offset-9___1SxN0 {
    margin-left: 75%;
  }

  .flexboxgrid2__col-sm-offset-10___2qwiO {
    margin-left: 83.33333333%;
  }

  .flexboxgrid2__col-sm-offset-11___3zRYq {
    margin-left: 91.66666667%;
  }

  .flexboxgrid2__start-sm___3I0F0 {
    justify-content: flex-start;
    text-align: start;
  }

  .flexboxgrid2__center-sm___2tfwT {
    justify-content: center;
    text-align: center;
  }

  .flexboxgrid2__end-sm___1Dqio {
    justify-content: flex-end;
    text-align: end;
  }

  .flexboxgrid2__top-sm___NTmPJ {
    align-items: flex-start;
  }

  .flexboxgrid2__middle-sm___QLrL0 {
    align-items: center;
  }

  .flexboxgrid2__bottom-sm___3iRAD {
    align-items: flex-end;
  }

  .flexboxgrid2__around-sm___2DXbH {
    justify-content: space-around;
  }

  .flexboxgrid2__between-sm___1GD_q {
    justify-content: space-between;
  }

  .flexboxgrid2__first-sm___AlKeI {
    order: -1;
  }

  .flexboxgrid2__last-sm___1GaXQ {
    order: 1;
  }

  .flexboxgrid2__initial-order-sm___3Ar7f {
    order: initial;
  }
}

@media only screen and (min-width: 768px) {
  .flexboxgrid2__col-md___srMeB,
  .flexboxgrid2__col-md-1___3RdWY,
  .flexboxgrid2__col-md-2___3j8qs,
  .flexboxgrid2__col-md-3___2Th4S,
  .flexboxgrid2__col-md-4___3pbbS,
  .flexboxgrid2__col-md-5___1Svz9,
  .flexboxgrid2__col-md-6___1wIAi,
  .flexboxgrid2__col-md-7___3z1EO,
  .flexboxgrid2__col-md-8___2Dm-W,
  .flexboxgrid2__col-md-9___1nXvw,
  .flexboxgrid2__col-md-10___3Br2r,
  .flexboxgrid2__col-md-11___3gKDL,
  .flexboxgrid2__col-md-12___2t4Kh,
  .flexboxgrid2__col-md-offset-0___1twEm,
  .flexboxgrid2__col-md-offset-1___12ZU0,
  .flexboxgrid2__col-md-offset-2___1dGCS,
  .flexboxgrid2__col-md-offset-3___XMXnG,
  .flexboxgrid2__col-md-offset-4___3TnIN,
  .flexboxgrid2__col-md-offset-5___EGfBj,
  .flexboxgrid2__col-md-offset-6___3Kb3E,
  .flexboxgrid2__col-md-offset-7___21XFw,
  .flexboxgrid2__col-md-offset-8___qnljU,
  .flexboxgrid2__col-md-offset-9___kdDX2,
  .flexboxgrid2__col-md-offset-10___284iF,
  .flexboxgrid2__col-md-offset-11___WXAgk,
  .flexboxgrid2__col-md-offset-12___2XYlU {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  .flexboxgrid2__col-md___srMeB {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .flexboxgrid2__col-md-1___3RdWY {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .flexboxgrid2__col-md-2___3j8qs {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .flexboxgrid2__col-md-3___2Th4S {
    flex-basis: 25%;
    max-width: 25%;
  }

  .flexboxgrid2__col-md-4___3pbbS {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .flexboxgrid2__col-md-5___1Svz9 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .flexboxgrid2__col-md-6___1wIAi {
    flex-basis: 50%;
    max-width: 50%;
  }

  .flexboxgrid2__col-md-7___3z1EO {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .flexboxgrid2__col-md-8___2Dm-W {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .flexboxgrid2__col-md-9___1nXvw {
    flex-basis: 75%;
    max-width: 75%;
  }

  .flexboxgrid2__col-md-10___3Br2r {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .flexboxgrid2__col-md-11___3gKDL {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .flexboxgrid2__col-md-12___2t4Kh {
    flex-basis: 100%;
    max-width: 100%;
  }

  .flexboxgrid2__col-md-offset-0___1twEm {
    margin-left: 0;
  }

  .flexboxgrid2__col-md-offset-1___12ZU0 {
    margin-left: 8.33333333%;
  }

  .flexboxgrid2__col-md-offset-2___1dGCS {
    margin-left: 16.66666667%;
  }

  .flexboxgrid2__col-md-offset-3___XMXnG {
    margin-left: 25%;
  }

  .flexboxgrid2__col-md-offset-4___3TnIN {
    margin-left: 33.33333333%;
  }

  .flexboxgrid2__col-md-offset-5___EGfBj {
    margin-left: 41.66666667%;
  }

  .flexboxgrid2__col-md-offset-6___3Kb3E {
    margin-left: 50%;
  }

  .flexboxgrid2__col-md-offset-7___21XFw {
    margin-left: 58.33333333%;
  }

  .flexboxgrid2__col-md-offset-8___qnljU {
    margin-left: 66.66666667%;
  }

  .flexboxgrid2__col-md-offset-9___kdDX2 {
    margin-left: 75%;
  }

  .flexboxgrid2__col-md-offset-10___284iF {
    margin-left: 83.33333333%;
  }

  .flexboxgrid2__col-md-offset-11___WXAgk {
    margin-left: 91.66666667%;
  }

  .flexboxgrid2__start-md___3M-iK {
    justify-content: flex-start;
    text-align: start;
  }

  .flexboxgrid2__center-md___3Ql1d {
    justify-content: center;
    text-align: center;
  }

  .flexboxgrid2__end-md___STrsQ {
    justify-content: flex-end;
    text-align: end;
  }

  .flexboxgrid2__top-md___2FX25 {
    align-items: flex-start;
  }

  .flexboxgrid2__middle-md___YZ6CJ {
    align-items: center;
  }

  .flexboxgrid2__bottom-md___2Ruw8 {
    align-items: flex-end;
  }

  .flexboxgrid2__around-md___1G_h0 {
    justify-content: space-around;
  }

  .flexboxgrid2__between-md___1ik_I {
    justify-content: space-between;
  }

  .flexboxgrid2__first-md___yFUKj {
    order: -1;
  }

  .flexboxgrid2__last-md___1PHhp {
    order: 1;
  }

  .flexboxgrid2__initial-order-md___3UvRN {
    order: initial;
  }
}

@media only screen and (min-width: 992px) {
  .flexboxgrid2__col-lg___3u7lk,
  .flexboxgrid2__col-lg-1___2y0lP,
  .flexboxgrid2__col-lg-2___1x6vt,
  .flexboxgrid2__col-lg-3___37wpY,
  .flexboxgrid2__col-lg-4___RwCNM,
  .flexboxgrid2__col-lg-5___37365,
  .flexboxgrid2__col-lg-6___NeTjn,
  .flexboxgrid2__col-lg-7___3bixv,
  .flexboxgrid2__col-lg-8___2YhQ1,
  .flexboxgrid2__col-lg-9___2e0uZ,
  .flexboxgrid2__col-lg-10___3X-8g,
  .flexboxgrid2__col-lg-11___1Ymgu,
  .flexboxgrid2__col-lg-12___p4dm-,
  .flexboxgrid2__col-lg-offset-0___YMDi3,
  .flexboxgrid2__col-lg-offset-1___2mUfM,
  .flexboxgrid2__col-lg-offset-2___2PSlK,
  .flexboxgrid2__col-lg-offset-3___2ZEsJ,
  .flexboxgrid2__col-lg-offset-4___oUBjv,
  .flexboxgrid2__col-lg-offset-5___2_pNE,
  .flexboxgrid2__col-lg-offset-6___1bZES,
  .flexboxgrid2__col-lg-offset-7___26quH,
  .flexboxgrid2__col-lg-offset-8___3kkd3,
  .flexboxgrid2__col-lg-offset-9___22Nlu,
  .flexboxgrid2__col-lg-offset-10___32R4D,
  .flexboxgrid2__col-lg-offset-11___3mcm9,
  .flexboxgrid2__col-lg-offset-12___1CzWw {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  .flexboxgrid2__col-lg___3u7lk {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .flexboxgrid2__col-lg-1___2y0lP {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .flexboxgrid2__col-lg-2___1x6vt {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .flexboxgrid2__col-lg-3___37wpY {
    flex-basis: 25%;
    max-width: 25%;
  }

  .flexboxgrid2__col-lg-4___RwCNM {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .flexboxgrid2__col-lg-5___37365 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .flexboxgrid2__col-lg-6___NeTjn {
    flex-basis: 50%;
    max-width: 50%;
  }

  .flexboxgrid2__col-lg-7___3bixv {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .flexboxgrid2__col-lg-8___2YhQ1 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .flexboxgrid2__col-lg-9___2e0uZ {
    flex-basis: 75%;
    max-width: 75%;
  }

  .flexboxgrid2__col-lg-10___3X-8g {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .flexboxgrid2__col-lg-11___1Ymgu {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .flexboxgrid2__col-lg-12___p4dm- {
    flex-basis: 100%;
    max-width: 100%;
  }

  .flexboxgrid2__col-lg-offset-0___YMDi3 {
    margin-left: 0;
  }

  .flexboxgrid2__col-lg-offset-1___2mUfM {
    margin-left: 8.33333333%;
  }

  .flexboxgrid2__col-lg-offset-2___2PSlK {
    margin-left: 16.66666667%;
  }

  .flexboxgrid2__col-lg-offset-3___2ZEsJ {
    margin-left: 25%;
  }

  .flexboxgrid2__col-lg-offset-4___oUBjv {
    margin-left: 33.33333333%;
  }

  .flexboxgrid2__col-lg-offset-5___2_pNE {
    margin-left: 41.66666667%;
  }

  .flexboxgrid2__col-lg-offset-6___1bZES {
    margin-left: 50%;
  }

  .flexboxgrid2__col-lg-offset-7___26quH {
    margin-left: 58.33333333%;
  }

  .flexboxgrid2__col-lg-offset-8___3kkd3 {
    margin-left: 66.66666667%;
  }

  .flexboxgrid2__col-lg-offset-9___22Nlu {
    margin-left: 75%;
  }

  .flexboxgrid2__col-lg-offset-10___32R4D {
    margin-left: 83.33333333%;
  }

  .flexboxgrid2__col-lg-offset-11___3mcm9 {
    margin-left: 91.66666667%;
  }

  .flexboxgrid2__start-lg___m0vFF {
    justify-content: flex-start;
    text-align: start;
  }

  .flexboxgrid2__center-lg___1ppmu {
    justify-content: center;
    text-align: center;
  }

  .flexboxgrid2__end-lg___1Cene {
    justify-content: flex-end;
    text-align: end;
  }

  .flexboxgrid2__top-lg___3R_GA {
    align-items: flex-start;
  }

  .flexboxgrid2__middle-lg___2vRr_ {
    align-items: center;
  }

  .flexboxgrid2__bottom-lg___1FxHX {
    align-items: flex-end;
  }

  .flexboxgrid2__around-lg___24PfH {
    justify-content: space-around;
  }

  .flexboxgrid2__between-lg___2a-N0 {
    justify-content: space-between;
  }

  .flexboxgrid2__first-lg___351pp {
    order: -1;
  }

  .flexboxgrid2__last-lg___2GWNG {
    order: 1;
  }

  .flexboxgrid2__initial-order-lg___3ajeo {
    order: initial;
  }
}

@media only screen and (min-width: 1200px) {
  .flexboxgrid2__col-xl___3OIWS,
  .flexboxgrid2__col-xl-1___1x-yZ,
  .flexboxgrid2__col-xl-2___oe1yn,
  .flexboxgrid2__col-xl-3___1DZkD,
  .flexboxgrid2__col-xl-4___e7X-g,
  .flexboxgrid2__col-xl-5___1K3om,
  .flexboxgrid2__col-xl-6___pj3oz,
  .flexboxgrid2__col-xl-7___2lbXv,
  .flexboxgrid2__col-xl-8___2T9rc,
  .flexboxgrid2__col-xl-9___4Cdy9,
  .flexboxgrid2__col-xl-10___pgLUE,
  .flexboxgrid2__col-xl-11___fOAzP,
  .flexboxgrid2__col-xl-12___1lxVN,
  .flexboxgrid2__col-xl-offset-0___rTVg4,
  .flexboxgrid2__col-xl-offset-1___1KRTF,
  .flexboxgrid2__col-xl-offset-2___3XTdA,
  .flexboxgrid2__col-xl-offset-3___1u7VM,
  .flexboxgrid2__col-xl-offset-4___1U3cj,
  .flexboxgrid2__col-xl-offset-5___1m-Bk,
  .flexboxgrid2__col-xl-offset-6___zqsMR,
  .flexboxgrid2__col-xl-offset-7___8fHBq,
  .flexboxgrid2__col-xl-offset-8___1LruZ,
  .flexboxgrid2__col-xl-offset-9___3oTGD,
  .flexboxgrid2__col-xl-offset-10___2eReq,
  .flexboxgrid2__col-xl-offset-11___kuo4A,
  .flexboxgrid2__col-xl-offset-12___1WvjR {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  .flexboxgrid2__col-xl___3OIWS {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .flexboxgrid2__col-xl-1___1x-yZ {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .flexboxgrid2__col-xl-2___oe1yn {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .flexboxgrid2__col-xl-3___1DZkD {
    flex-basis: 25%;
    max-width: 25%;
  }

  .flexboxgrid2__col-xl-4___e7X-g {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .flexboxgrid2__col-xl-5___1K3om {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .flexboxgrid2__col-xl-6___pj3oz {
    flex-basis: 50%;
    max-width: 50%;
  }

  .flexboxgrid2__col-xl-7___2lbXv {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .flexboxgrid2__col-xl-8___2T9rc {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .flexboxgrid2__col-xl-9___4Cdy9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .flexboxgrid2__col-xl-10___pgLUE {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .flexboxgrid2__col-xl-11___fOAzP {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .flexboxgrid2__col-xl-12___1lxVN {
    flex-basis: 100%;
    max-width: 100%;
  }

  .flexboxgrid2__col-xl-offset-0___rTVg4 {
    margin-left: 0;
  }

  .flexboxgrid2__col-xl-offset-1___1KRTF {
    margin-left: 8.33333333%;
  }

  .flexboxgrid2__col-xl-offset-2___3XTdA {
    margin-left: 16.66666667%;
  }

  .flexboxgrid2__col-xl-offset-3___1u7VM {
    margin-left: 25%;
  }

  .flexboxgrid2__col-xl-offset-4___1U3cj {
    margin-left: 33.33333333%;
  }

  .flexboxgrid2__col-xl-offset-5___1m-Bk {
    margin-left: 41.66666667%;
  }

  .flexboxgrid2__col-xl-offset-6___zqsMR {
    margin-left: 50%;
  }

  .flexboxgrid2__col-xl-offset-7___8fHBq {
    margin-left: 58.33333333%;
  }

  .flexboxgrid2__col-xl-offset-8___1LruZ {
    margin-left: 66.66666667%;
  }

  .flexboxgrid2__col-xl-offset-9___3oTGD {
    margin-left: 75%;
  }

  .flexboxgrid2__col-xl-offset-10___2eReq {
    margin-left: 83.33333333%;
  }

  .flexboxgrid2__col-xl-offset-11___kuo4A {
    margin-left: 91.66666667%;
  }

  .flexboxgrid2__start-xl___2Ur_r {
    justify-content: flex-start;
    text-align: start;
  }

  .flexboxgrid2__center-xl___3C4Vx {
    justify-content: center;
    text-align: center;
  }

  .flexboxgrid2__end-xl___2UqlC {
    justify-content: flex-end;
    text-align: end;
  }

  .flexboxgrid2__top-xl___1tLbF {
    align-items: flex-start;
  }

  .flexboxgrid2__middle-xl___31-ID {
    align-items: center;
  }

  .flexboxgrid2__bottom-xl___p1SvR {
    align-items: flex-end;
  }

  .flexboxgrid2__around-xl___2j28w {
    justify-content: space-around;
  }

  .flexboxgrid2__between-xl___xff2E {
    justify-content: space-between;
  }

  .flexboxgrid2__first-xl___2QxqG {
    order: -1;
  }

  .flexboxgrid2__last-xl___1v5wI {
    order: 1;
  }

  .flexboxgrid2__initial-order-xl___IYbgP {
    order: initial;
  }
}

@media only screen and (max-width: 575px) {
  .flexboxgrid2__hidden-xs___3MgtY {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flexboxgrid2__hidden-sm___2YMAO {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flexboxgrid2__hidden-md___FbTYO {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flexboxgrid2__hidden-lg___2fkvW {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .flexboxgrid2__hidden-xl___3hzYJ {
    display: none;
  }
}
